/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Typography, Container, CssBaseline } from '@mui/material';
import toast from "react-hot-toast";
import { useLogin } from '../services/mutations';

const Login = ({ setAuth, setUsername, setRole }) => {
  const [usernameInput, setUsernameInput] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const loginMutation = useLogin();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) navigate('/');
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!usernameInput || !password) {
      setError('Username and Password are required');
      toast.error('Username and Password are required');
      return;
    }

    loginMutation.mutate(
      { username: usernameInput, password },
      {
        onSuccess: async (res) => {
          const token = res?.data?.access_token;
          if (token) {
            localStorage.setItem('token', token);
            setAuth(true);
            setUsername(usernameInput);
            navigate('/');
          }
        },
        onError: (err) => {
          const msg = err?.response?.data?.detail || 'Login failed';
          setError(msg);
          toast.error(`Error, ${msg}`);
        },
      }
    );
  };

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f0f0f0',
      }}
    >
      <CssBaseline />
      <Container
        sx={{ p: 3, borderRadius: 2, display: 'flex', alignItems: 'center', gap: 2, background: 'white' }}
        component="main"
        maxWidth="md"
      >
        <Box width={350} display="flex" alignItems="center" justifyContent="center">
          <img src="/tvri.jpg" alt="TVRI Logo" style={{ width: 200 }} />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography component="h1" variant="h5">
            Log In
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Welcome to TVRI SuperApp
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username/NIK/Email"
              name="username"
              autoComplete="username"
              autoFocus
              value={usernameInput}
              onChange={(e) => setUsernameInput(e.target.value)}
              error={!!error}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={!!error}
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, p: 2 }}>
              Log In
            </Button>
            {error && (
              <Typography color="error" variant="body2" align="center">
                {error}
              </Typography>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Login;
