import React, { createContext, useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { mockCards } from '../mockData';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      // Uncomment the following line when backend is ready
      // const response = await fetchCards();
      // setCards(response.data);
      
      // Use mock data for now
      setCards(mockCards);
    } catch (error) {
      console.error('Failed to fetch cards:', error);
      setError('Failed to fetch cards');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const value = useMemo(() => ({ cards, loading, error }), [cards, loading, error]);

  return (
    <DataContext.Provider value={value}>
      {children}
    </DataContext.Provider>
  );
};

DataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
