import React, { useState, useEffect } from 'react';
import {
  Box, Typography, CircularProgress, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, Avatar, Chip, Stack
} from '@mui/material';

const mock = {
  perf_logs: [
    {
      id: 63,
      method: "POST",
      url: "http://localhost:8080/api/v1/role/SIMPEG/permissions",
      api: "User Management",
      resp_status: 422,
      created_at: "2024-06-05T04:40:34.090Z",
      created_by: {
        username: "jdoe",
        full_name: "John Doe",
        profile: {
          position: "Job's Position",
          employee_id: "123456789"
        },
        picture_url: "https://i.pravatar.cc/150?u=johndoe",
        roles: [
          {
            code: "SIPALINGPERAN",
            name: "Si Paliiiingng Peran"
          }
        ],
        created_at: "2024-05-28T00:53:31.771285",
        updated_at: null
      }
    },
    {
      id: 2,
      method: "GET",
      url: "http://localhost:8080/docs",
      api: "User Management",
      resp_status: 200,
      created_at: "2024-05-27T13:15:17.295Z",
      created_by: {
        username: "jdoe",
        full_name: "John Doe",
        profile: {
          position: "Job's Position",
          employee_id: "123456789"
        },
        picture_url: "https://i.pravatar.cc/150?u=johndoe",
        roles: [
          {
            code: "SIPALINGPERAN",
            name: "Si Paliiiingng Peran"
          }
        ],
        created_at: "2024-05-28T00:53:31.771285",
        updated_at: null
      }
    },
    {
      id: 1,
      method: "GET",
      url: "http://localhost:8080/",
      api: "User Management",
      resp_status: 404,
      created_at: "2024-05-27T13:15:12.852Z",
      created_by: {
        username: "jdoe",
        full_name: "John Doe",
        profile: {
          position: "Job's Position",
          employee_id: "123456789"
        },
        picture_url: "https://i.pravatar.cc/150?u=johndoe",
        roles: [
          {
            code: "SIPALINGPERAN",
            name: "Si Paliiiingng Peran"
          }
        ],
        created_at: "2024-05-28T00:53:31.771285",
        updated_at: null
      }
    }
  ]
};

const ActivityLog = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    // Simulate fetching data
    setTimeout(() => {
      setLogs(mock.perf_logs);
      setIsLoading(false);
    }, 500);
  }, []);

  return (
    <Box>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" gutterBottom>Activity Log</Typography>
      </Stack>
      {isLoading ? (
        <Box display="flex" justifyContent="center" mt={10}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper} sx={{ mt: 3 }}>
          <Table sx={{ border: '2px solid rgba(224, 224, 224, 1)' }}>
            <TableHead>
              <TableRow>
                <TableCell width={50} sx={{ fontWeight: 'bold', borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>No</TableCell>
                <TableCell sx={{ fontWeight: 'bold', borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>Method</TableCell>
                <TableCell sx={{ fontWeight: 'bold', borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>URL</TableCell>
                <TableCell sx={{ fontWeight: 'bold', borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>API</TableCell>
                <TableCell sx={{ fontWeight: 'bold', borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>Status</TableCell>
                <TableCell sx={{ fontWeight: 'bold', borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>Timestamp</TableCell>
                <TableCell sx={{ fontWeight: 'bold', borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>User</TableCell>
                <TableCell sx={{ fontWeight: 'bold', borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>Role</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((log, index) => (
                <TableRow key={log.id}>
                  <TableCell sx={{ borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>{index + 1}</TableCell>
                  <TableCell sx={{ borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>{log.method}</TableCell>
                  <TableCell sx={{ borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>{log.url}</TableCell>
                  <TableCell sx={{ borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>{log.api}</TableCell>
                  <TableCell sx={{ borderBottom: '2px solid rgba(224, 224, 224, 1)', color: log.resp_status === 200 ? 'green' : 'red' }}>{log.resp_status}</TableCell>
                  <TableCell sx={{ borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>{new Date(log.created_at).toLocaleString()}</TableCell>
                  <TableCell sx={{ borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>
                    <Box display="flex" alignItems="center">
                      <Avatar alt={log.created_by.full_name} src={log.created_by.picture_url} sx={{ width: 40, height: 40, mr: 2 }} />
                      <Box>
                        <Typography>{log.created_by.full_name}</Typography>
                        <Typography variant="caption">{log.created_by.profile.position}</Typography>
                        <Typography variant="caption">{log.created_by.profile.employee_id}</Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>
                    {log.created_by.roles.map(role => (
                      <Chip key={role.code} label={role.name} sx={{ marginRight: 0.5, fontSize: 12 }} />
                    ))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default ActivityLog;
