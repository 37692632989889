import React, { useState, useCallback } from 'react';
import { Drawer as MUIDrawer, List, ListItemButton, Box, ListItemIcon, ListItemText } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { HomeOutlined, GroupOutlined, AppsOutlined, DvrOutlined, SettingsOutlined } from '@mui/icons-material';
import { INTERNAL_APPS } from '../helper/constants';
import useAuth from '../hooks/useAuth';

const drawerWidth = 256;
const collapsedWidth = 64;

const Drawer = () => {
  const [isHovered, setIsHovered] = useState(false);
  const loc = useLocation();
  const { filteredApps } = useAuth();

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  return (
    <MUIDrawer
      variant="permanent"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        ...styles.drawer,
        width: isHovered ? drawerWidth : collapsedWidth,
        [`& .MuiDrawer-paper`]: {
          ...styles.drawerPaper,
          width: isHovered ? drawerWidth : collapsedWidth,
        },
      }}
    >
      <Box sx={{ overflow: 'hidden' }}>
        <List>
          {filteredApps.map((item) => (
            <ListItemButton
              key={item.text}
              sx={{
                ...styles.listItemButton,
                color: loc?.pathname.includes(item.path) ? '#1876d2' : '#333',
              }}
              component={Link}
              to={item.path}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              {isHovered && <ListItemText primary={item.text} />}
            </ListItemButton>
          ))}
        </List>
      </Box>
    </MUIDrawer>
  );
};

const styles = {
  drawer: {
    flexShrink: 0,
    overflow: 'hidden',
  },
  drawerPaper: {
    boxSizing: 'border-box',
    top: '64px',
    transition: 'width 0.3s',
    overflowX: 'hidden',
  },
  listItemButton: {
    py: 2,
  },
};

const menuItems = [
  { text: 'Dashboard', path: '/', icon: <HomeOutlined /> },
  { text: 'User Management', path: '/user-management', icon: <GroupOutlined /> },
  { text: 'Role Management', path: '/role-management', icon: <SettingsOutlined /> },
  { text: 'Application Management', path: '/applications', icon: <AppsOutlined /> },
  { text: 'Activity Log', path: '/activity-log', icon: <DvrOutlined /> },
];

export default Drawer;
