import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, CssBaseline, CircularProgress, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';
import AppBar from './AppBar';
import Drawer from './Drawer';
import useAuth from '../hooks/useAuth';
import { useLocation } from "react-router-dom"

const Layout = ({ setAuth, username }) => {
  const { role, status, filteredApps } = useAuth();
  const { pathname } = useLocation()
  const routes = filteredApps.map(el => el.path);

  const isSuperAdmin = useMemo(() => {
    return role.some(el => el.code === 'ADMIN');
  }, [role]);

  if (status === 'idle' || status === 'loading') return
  <Box display="flex" justifyContent="center" mt={10}>
    <CircularProgress />
  </Box>

  const NotFound = () => <Typography variant='h4' sx={{ textAlign: 'center', mt: 10 }}>Page not found</Typography>

  return (
    <Box sx={styles.container}>
      <CssBaseline />
      <AppBar username={username} setAuth={setAuth} role={role} />
      {/* {isSuperAdmin && <Drawer />} */}
      <Drawer />
      <Box component="main" sx={styles.main(isSuperAdmin)}>
        {!routes.some(app => pathname.startsWith(app)) ? <NotFound /> : <Outlet />}
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    display: 'flex',
  },
  main: (isSuperAdmin) => ({
    flexGrow: 1,
    p: 3,
    mt: 8,
  }),
};

Layout.propTypes = {
  setAuth: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
};

export default Layout;
