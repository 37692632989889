import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardActionArea, CardContent, Box, Typography, Stack, Chip } from '@mui/material';
import { useRedirectLink } from '../services/queries';

const DashboardCard = ({ card, isInternal, setSelectedCard }) => {
  const [url, setUrl] = useState('')
  const { isLoading, data, error, refetch } = useRedirectLink(url);

  const handleClick = useCallback(() => {
    if (isInternal) return window.open(card.path, '_self')
    if (!card.is_connected) return setSelectedCard(card)
    const url = card?.url?.split('=') || ''
    return setUrl(url[1]);
    // isInternal ?
    //   window.open(card.path, '_self') :
    //   window.open(card.host, '_blank');
  }, [card.host]);

  useEffect(() => {
    if (data) {
      const formattedUrl = data?.data?.url.match(/^https?:/) ? data?.data?.url : '//' + data?.data?.url;
      window.open(formattedUrl, '_blank');
      setUrl('')
    }
  }, [data])

  return (
    <Card
      sx={styles.card}
      onClick={handleClick}
    >
      <CardActionArea sx={styles.cardActionArea}>
        <CardContent sx={styles.cardContent}>
          {
            isInternal ?
              <Stack direction={'row'} alignItems={'center'}>
                {card.icon}
                <Typography ml={1}>{card.text}</Typography>
              </Stack> :
              <img src="/tvri.jpg" alt="TVRI Logo" style={styles.logo} />
          }
          {
            !isInternal &&
            <Box textAlign="center" mt={2}>
              <Typography fontWeight="bold">{card.name}</Typography>
              <Typography color="#1876d2">{card.host}</Typography>
              <Typography
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '2',
                  WebkitBoxOrient: 'vertical',
                  my: 1,
                }}
                variant="body2" color="text.secondary">
                {card.description}
              </Typography>
              <Chip color={card.is_connected ? 'success' : 'default'} size='small' label={card.is_connected ? 'Active' : 'Inactive'} />
            </Box>
          }
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const styles = {
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    cursor: 'pointer',
    backgroundColor: '#fff',
    '&:hover': {
      boxShadow: 6,
      backgroundColor: '#f0f0f0'
    },
    height: '100%'
  },
  cardActionArea: {
    flexGrow: 1,
  },
  cardContent: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: 50,
    objectFit: 'contain',
  }
};

// DashboardCard.propTypes = {
//   card: PropTypes.shape({
//     name: PropTypes.string.isRequired,
//     host: PropTypes.string.isRequired,
//     description: PropTypes.string.isRequired,
//     is_active: PropTypes.bool.isRequired,
//   }).isRequired,
// };

export default React.memo(DashboardCard);
