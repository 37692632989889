import React, { useState, useEffect, useCallback, useMemo, useReducer } from 'react';
import {
  Box, Typography, Button, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, IconButton, Chip, TextField,
  CircularProgress, Alert, Stack, Autocomplete, Divider, Card, CardContent, Grid, ButtonGroup, Menu, MenuItem
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import { useUsers, useReferenceRoles, useUserDetail } from '../services/queries';
import { useCreateUser, useUpdateUser, useDeleteUser } from '../services/mutations';
import { toast } from 'react-hot-toast';
import FormDrawer from '../components/FormDrawer';
import ConfirmationModal from '../components/ConfirmationModal';
import useAuth from '../hooks/useAuth';

const initialFormState = {
  first_name: "",
  last_name: "",
  username: "",
  password: "",
  email: "",
  roles: [],
  position: "",
  employee_id: "",
  credentials: []
};

const formReducer = (state, action) => {
  switch (action.type) {
    case 'SET_FORM':
      return { ...state, [action.field]: action.value };
    case 'RESET_FORM':
      return initialFormState;
    case 'SET_FORM_ALL':
      return action.payload;
    case 'SET_CREDENTIALS':
      return {
        ...state,
        credentials: state.credentials.map(cred =>
          cred.application_code === action.payload.application_code
            ? { ...cred, ...action.payload }
            : cred
        ),
      };
    case 'ADD_CREDENTIAL':
      if (!state.credentials.some(cred => cred.application_code === action.payload.application_code)) {
        return {
          ...state,
          credentials: [...state.credentials, action.payload],
        };
      }
      return state;
    case 'REMOVE_CREDENTIAL':
      return {
        ...state,
        credentials: state.credentials.filter(cred => cred.application_code !== action.payload),
      };
    default:
      return state;
  }
};

const UserManagement = () => {
  const { isLoading, data, error, refetch } = useUsers();
  const { data: dataRoles, refetch: refetchRoles } = useReferenceRoles();
  const createUser = useCreateUser();
  const updateUser = useUpdateUser();
  const deleteUser = useDeleteUser();
  const { checkPermissionElement } = useAuth();

  const [isOpen, setIsOpen] = useState(false);
  const [form, dispatch] = useReducer(formReducer, initialFormState);
  const [editMode, setEditMode] = useState(false);
  const [selectedUsername, setSelectedUsername] = useState(null);
  const [formError, setFormError] = useState(null);
  const [localData, setLocalData] = useState([]);
  const [loading, setLoading] = useState({ submit: false, delete: false });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [usernameToDelete, setUsernameToDelete] = useState(null);
  const [applications, setApplications] = useState([]);
  const [validationError, setValidationError] = useState({
    application_code: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [bulkMode, setBulkMode] = useState(false);
  const [file, setFile] = useState(null);

  const { data: userDetail, isFetching: isFetchingDetail, refetch: refetchUserDetail } = useUserDetail(selectedUsername, {
    enabled: !!selectedUsername
  });

  useEffect(() => {
    refetch();
    refetchRoles();
  }, [refetch, refetchRoles]);

  useEffect(() => {
    if (data) {
      setLocalData(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (userDetail) {
      const { firstName, lastName } = splitFullName(userDetail.data.full_name);

      dispatch({
        type: 'SET_FORM_ALL',
        payload: {
          first_name: firstName || "",
          last_name: lastName || "",
          username: userDetail.data.username || "",
          password: "",
          email: userDetail.data.email || "",
          roles: userDetail.data.roles || [],
          position: userDetail.data.profile ? userDetail.data.profile.position || "" : "",
          employee_id: userDetail.data.profile ? userDetail.data.profile.employee_id || "" : "",
          credentials: userDetail.data.credentials && userDetail.data.credentials.length > 0 ? userDetail.data.credentials : initialFormState.credentials
        }
      });

      const userApplications = userDetail.data.roles.reduce((acc, role) => {
        const roleDetail = dataRoles?.data?.find(r => r.role === role);
        if (roleDetail) {
          roleDetail.applications.forEach(app => {
            if (!acc.some(a => a.application_code === app.code)) {
              acc.push({ application_code: app.code, application_name: app.name, has_credentials: app.has_credentials });
              if (!app.has_credentials) {
                dispatch({
                  type: 'ADD_CREDENTIAL',
                  payload: { application_code: app.code, username: "", password: "" }
                });
              }
            }
          });
        }
        return acc;
      }, []);
      setApplications(userApplications);
    }
  }, [userDetail, dataRoles]);

  const splitFullName = (fullName) => {
    const fullNameParts = fullName ? fullName.split(' ') : ["", ""];
    const firstName = fullNameParts[0];
    const lastName = fullNameParts.slice(1).join(' ');

    return { firstName, lastName };
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    dispatch({ type: 'SET_FORM', field: name, value });
    setValidationError({ ...validationError, [name]: "" });
  };

  const handleAutocompleteChange = (event, newValue) => {
    const updatedRoles = newValue.map(role => role.role);
    dispatch({ type: 'SET_FORM', field: 'roles', value: updatedRoles });

    const selectedApplications = newValue.reduce((acc, role) => {
      const roleDetail = dataRoles?.data?.find(r => r.role === role.role);
      if (roleDetail) {
        roleDetail.applications.forEach(app => {
          if (!acc.some(a => (a.application_code === app.code && a.application_code.toLowercase() !== 'superapp'))) {
            acc.push({ application_code: app.code, application_name: app.name, has_credentials: app.has_credentials });
            if (!app.has_credentials) {
              dispatch({
                type: 'ADD_CREDENTIAL',
                payload: { application_code: app.code, username: "", password: "" }
              });
            }
          }
        });
      }
      return acc;
    }, []);
    setApplications(selectedApplications);
  };

  const handleCredentialChange = (appCode, field, value) => {
    dispatch({
      type: 'SET_CREDENTIALS',
      payload: { application_code: appCode, [field]: value }
    });
    setValidationError({ ...validationError, [field]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormError(null);

    const payload = { ...form, roles: form.roles !== undefined ? form.roles : [] };

    // let valid = true;
    // const newValidationError = {
    //   application_code: "",
    //   username: "",
    //   password: ""
    // };

    // form.credentials.forEach(cred => {
    //   if (!cred.application_code) newValidationError.application_code = "Application code is required";
    //   if (!cred.username) newValidationError.username = "Username is required";
    //   if (!cred.password) newValidationError.password = "Password is required";
    // });

    // setValidationError(newValidationError);
    // valid = Object.values(newValidationError).every(error => error === "");

    // if (!valid) return;

    payload.credentials = form.credentials.filter(
      cred => cred.username && cred.password
    );

    Object.keys(payload).forEach(key => {
      if (payload[key] === "" || (Array.isArray(payload[key]) && payload[key].length === 0)) {
        delete payload[key];
      }
    });

    // console.log('Payload sebelum submit:', payload);

    setLoading((prevLoading) => ({ ...prevLoading, submit: true }));
    try {
      const response = editMode
        ? await updateUser.mutateAsync({ username: selectedUsername, body: payload })
        : await createUser.mutateAsync(payload);

      console.log('Response dari server:', response);

      toast.success(editMode ? 'User updated successfully' : 'User created successfully', {
        className: 'toast-success',
      });

      if (editMode) {
        refetchUserDetail();
      }

      setIsOpen(false);
      dispatch({ type: 'RESET_FORM' });
      refetch();
    } catch (error) {
      setFormError(error.response?.data?.detail || error.message);
    } finally {
      setLoading((prevLoading) => ({ ...prevLoading, submit: false }));
    }
  };

  const handleDelete = useCallback((username) => {
    setUsernameToDelete(username);
    setIsModalOpen(true);
  }, []);

  const confirmDelete = async () => {
    setLoading((prevLoading) => ({ ...prevLoading, delete: true }));
    try {
      await deleteUser.mutateAsync(usernameToDelete);
      toast.success('User deleted successfully', {
        className: 'toast-success',
      });
      setIsModalOpen(false);
      refetch();
    } catch (error) {
      console.error("Error during user delete:", error);
    } finally {
      setLoading((prevLoading) => ({ ...prevLoading, delete: false }));
    }
  };

  const handleEdit = useCallback((user) => {
    setSelectedUsername(user.username);
    setEditMode(true);
    setBulkMode(false);
    setIsOpen(true);
  }, []);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleAddSingleUser = () => {
    dispatch({ type: 'RESET_FORM' });
    setEditMode(false);
    setBulkMode(false);
    setIsOpen(true);
    handleCloseMenu();
  };

  const handleAddBulkUsers = () => {
    dispatch({ type: 'RESET_FORM' });
    setEditMode(false);
    setBulkMode(true);
    setIsOpen(true);
    handleCloseMenu();
  };

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: '.xlsx, .xls, .csv' });

  const renderTableRows = useMemo(() => (
    localData.map((user, i) => (
      <TableRow key={user.username} style={{ textDecoration: user.is_deleted ? 'line-through' : 'none' }}>
        <TableCell>{i + 1}</TableCell>
        <TableCell>
          <Box display="flex" alignItems="center">
            <img src={user.picture_url} alt="User Profile" style={{ width: 40, marginRight: 10, borderRadius: 40 }} />
            <Box>
              <Typography>{user.full_name}</Typography>
              <Typography variant="caption">{user.username}</Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell>{user.profile?.employee_id || '-'}</TableCell>
        <TableCell>{user.profile?.position || '-'}</TableCell>
        <TableCell>
          {user.roles.length > 0 ? user.roles.map(el => <Chip key={el} sx={{ marginRight: 5, fontSize: 12 }} label={el} />) : '-'}
        </TableCell>
        <TableCell>
          {
            checkPermissionElement('superapp:user-management:button:edit') &&
            <IconButton onClick={() => handleEdit(user)} disabled={user.is_deleted}>
              <EditIcon fontSize="small" />
            </IconButton>
          }
          {
            checkPermissionElement('superapp:user-management:button:delete') &&
            <IconButton onClick={() => handleDelete(user.username)} disabled={user.is_deleted}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          }
        </TableCell>
      </TableRow>
    ))
  ), [localData, handleEdit, handleDelete]);

  return (
    <Box>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" gutterBottom>User Management</Typography>
        {
          checkPermissionElement('superapp:user-management:button:create') &&
          <ButtonGroup variant="contained" color="primary" size="small">
            <Button
              startIcon={<AddIcon />}
              onClick={handleClickMenu}
            >
              Add User
            </Button>
            <Button
              size="small"
              aria-controls="split-button-menu"
              aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleClickMenu}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
        }
        <Menu
          id="split-button-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleAddSingleUser}>Add Single User</MenuItem>
          <MenuItem onClick={handleAddBulkUsers}>Add Bulk Users</MenuItem>
        </Menu>
      </Stack>
      {error && <Alert severity="error" sx={{ mt: 2 }}>{error.message}</Alert>}
      {isLoading ? (
        <Box display="flex" justifyContent="center" mt={10}><CircularProgress /></Box>
      ) : (
        <TableContainer component={Paper} sx={{ mt: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>User</TableCell>
                <TableCell>NIP</TableCell>
                <TableCell>Jabatan</TableCell>
                <TableCell>Roles</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderTableRows}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <FormDrawer isOpen={isOpen} setIsOpen={setIsOpen}>
        <Box component="form" onSubmit={handleSubmit}>
          <Typography mb={3} variant="h6" color="primary">{editMode ? 'Edit User' : (bulkMode ? 'Add Bulk Users' : 'Add User')}</Typography>
          {formError && <Alert severity="error" sx={{ mb: 3 }}>{formError}</Alert>}
          {isFetchingDetail ? (
            <Box display="flex" justifyContent="center" mt={10}><CircularProgress /></Box>
          ) : (
            <>
              {!bulkMode ? (
                <>
                  <Typography fontWeight={'bold'} gutterBottom>Basic Information</Typography>
                  <Divider sx={{ mb: 2 }} />
                  <Grid container>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="employee_id"
                        label="NIP"
                        name="employee_id"
                        value={form.employee_id}
                        onChange={handleChangeInput}
                        sx={{ mb: 2 }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="first_name"
                        label="First Name"
                        name="first_name"
                        value={form.first_name}
                        onChange={handleChangeInput}
                        required
                        sx={{ mb: 2 }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="last_name"
                        label="Last Name"
                        name="last_name"
                        value={form.last_name}
                        onChange={handleChangeInput}
                        required
                        sx={{ mb: 2 }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        value={form.username}
                        onChange={handleChangeInput}
                        required
                        disabled={editMode}
                        sx={{ mb: 2 }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="password"
                        label="Password"
                        name="password"
                        type="password"
                        value={form.password}
                        onChange={handleChangeInput}
                        required={!editMode}
                        sx={{ mb: 2 }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        value={form.email}
                        onChange={handleChangeInput}
                        sx={{ mb: 2 }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="position"
                        label="Jabatan"
                        name="position"
                        value={form.position}
                        onChange={handleChangeInput}
                        sx={{ mb: 2 }}
                      />
                    </Grid>
                  </Grid>

                  <Typography fontWeight={'bold'} gutterBottom sx={{ mt: 4 }}>Roles and Applications</Typography>
                  <Divider sx={{ mb: 2 }} />
                  <Autocomplete
                    multiple
                    required
                    id="roles"
                    sx={{ mb: 3 }}
                    options={dataRoles?.data || []}
                    getOptionLabel={(option) => option.name || ''}
                    isOptionEqualToValue={(option, value) => option.role === value.role}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Roles"
                        placeholder="Roles"
                      />
                    )}
                    onChange={handleAutocompleteChange}
                    value={form.roles.map(role => dataRoles?.data?.find(r => r.role === role))}
                  />

                  {applications.filter(app => !app.has_credentials).map(app => (
                    <Card key={app.application_code} sx={{ mb: 3 }}>
                      <CardContent>
                        <Stack direction={'row'} alignItems={'center'} mb={3}>
                          <img src="/tvri.jpg" alt="TVRI Logo" width={40} style={{ marginRight: 10, objectFit: 'contain' }} />
                          <Typography gutterBottom>{app.application_name}</Typography>
                        </Stack>
                        <Grid container>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              id={`application_code_${app.application_code}`}
                              label="Application Code"
                              disabled
                              name={`application_code_${app.application_code}`}
                              value={form.credentials.find(cred => cred.application_code === app.application_code)?.application_code || ''}
                              onChange={(e) => handleCredentialChange(app.application_code, 'application_code', e.target.value)}
                              error={!!validationError.application_code}
                              helperText={validationError.application_code}
                              sx={{ mb: 2 }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              id={`cred_username_${app.application_code}`}
                              label="Username"
                              name={`cred_username_${app.application_code}`}
                              value={form.credentials.find(cred => cred.application_code === app.application_code)?.username || ''}
                              onChange={(e) => handleCredentialChange(app.application_code, 'username', e.target.value)}
                              sx={{ mb: 2 }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              type="password"
                              id={`cred_password_${app.application_code}`}
                              label="Password"
                              name={`cred_password_${app.application_code}`}
                              value={form.credentials.find(cred => cred.application_code === app.application_code)?.password || ''}
                              onChange={(e) => handleCredentialChange(app.application_code, 'password', e.target.value)}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  ))}
                </>
              ) : (
                <>
                  <Box {...getRootProps({ className: 'dropzone' })} sx={{
                    p: 5,
                    border: '2px dashed #eeeeee',
                    borderRadius: '2px',
                    backgroundColor: '#fafafa',
                    color: '#bdbdbd',
                    textAlign: 'center'
                  }}>
                    <input {...getInputProps()} />
                    <Typography variant="body1">Drag 'n' drop some files here, or click to select files</Typography>
                  </Box>
                  {file && (
                    <Typography variant="body2" mt={2}>Selected file: {file.name}</Typography>
                  )}
                </>
              )}
            </>
          )}
          <Box display="flex" justifyContent="center" mt={4}>
            <LoadingButton type="submit" variant="contained" color="primary" fullWidth loading={loading.submit}>
              {editMode ? 'Update' : 'Create'}
            </LoadingButton>
          </Box>
        </Box>
      </FormDrawer>

      <ConfirmationModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={confirmDelete}
        title="Confirm Delete"
        description="Are you sure you want to delete this user?"
      />
    </Box>
  );
};

export default UserManagement;
