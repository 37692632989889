import axios from 'axios';
import { useQuery } from 'react-query';

const url = process.env.REACT_APP_API_URL + '/api/v1';

// Membuat instance axios dengan interceptor
const createInstance = () => {
  const instance = axios.create({
    baseURL: url,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  return instance;
};

// Queries
export const useAuthMe = () => useQuery(['authMe'], async () => {
  const instance = createInstance();
  const { data } = await instance.get('/auth/me');
  return data;
}, { enabled: false });

export const useAuthApp = () => useQuery(['authApp'], async () => {
  const instance = createInstance();
  const { data } = await instance.get('/auth/app');
  return data;
}, { enabled: false });

export const useApplications = () => useQuery(['applications'], async () => {
  const instance = createInstance();
  const { data } = await instance.get('/application');
  return data;
}, { enabled: false });

export const useUsers = () => useQuery(['users'], async () => {
  const instance = createInstance();
  const { data } = await instance.get('/user');
  return data;
}, { enabled: false });

export const useUserDetail = (username) => useQuery(['user', username], async () => {
  const instance = createInstance();
  const { data } = await instance.get(`/user/${username}`);
  return data;
}, {
  enabled: !!username // Ensure query is only enabled when username is not null or undefined
});

export const useReferenceRoles = () => useQuery(['referenceRoles'], async () => {
  const instance = createInstance();
  const { data } = await instance.get('/reference/role');
  return data;
}, { enabled: false });

export const useReferenceApps = () => useQuery(['referenceApps'], async () => {
  const instance = createInstance();
  const { data } = await instance.get('/reference/application');
  return data;
}, { enabled: false });

export const useRoles = () => useQuery(['roles'], async () => {
  const instance = createInstance();
  const { data } = await instance.get('/role');
  return data;
}, { enabled: false });

export const useRoleDetail = (code) => useQuery(['role', code], async () => {
  const instance = createInstance();
  const { data } = await instance.get(`/role/${code}`);
  return data;
}, { enabled: false, retry: false });

export const usePermissions = () => useQuery(['permissions'], async () => {
  const instance = createInstance();
  const { data } = await instance.get('/permission');
  return data;
}, { enabled: false });

export const useRedirectLink = (url) => useQuery(['redirectLink', url], async () => {
  const instance = createInstance();
  const { data } = await instance.get(`auth/app/redirect?destination_url=${url}`);
  return data;
}, { enabled: !!url });