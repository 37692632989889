import { HomeOutlined, GroupOutlined, AppsOutlined, DvrOutlined, SettingsOutlined } from '@mui/icons-material';

export const USER_MGMT = {
    read: [
        'superapp:user-management:module:read',
        'superapp:user-management:view:read',
        'superapp:user-management:button:read'
    ],
    create: [
        'superapp:user-management:module:create',
        'superapp:user-management:view:create',
        'superapp:user-management:button:create',
    ],
    edit: [
        'superapp:user-management:module:edit',
        'superapp:user-management:view:edit',
        'superapp:user-management:button:edit',
    ],
    delete: [
        'superapp:user-management:module:delete',
        'superapp:user-management:view:delete',
        'superapp:user-management:button:delete',
    ]
}
export const ROLE_MGMT = {
    read: [
        'superapp:role-management:module:read',
        'superapp:role-management:view:read',
        'superapp:role-management:button:read',
    ],
    create: [
        'superapp:role-management:module:create',
        'superapp:role-management:view:create',
        'superapp:role-management:button:create',
    ],
    edit: [
        'superapp:role-management:module:edit',
        'superapp:role-management:view:edit',
        'superapp:role-management:button:edit',
    ],
    delete: [
        'superapp:role-management:module:delete',
        'superapp:role-management:view:delete',
        'superapp:role-management:button:delete',
    ]
}
export const APP_MGMT = {
    read: [
        'superapp:application-management:module:read',
        'superapp:application-management:view:read',
        'superapp:application-management:button:read',
    ],
    create: [
        'superapp:application-management:module:create',
        'superapp:application-management:view:create',
        'superapp:application-management:button:create',
    ],
    edit: [
        'superapp:application-management:module:edit',
        'superapp:application-management:view:edit',
        'superapp:application-management:button:edit',
    ],
    delete: [
        'superapp:application-management:module:delete',
        'superapp:application-management:view:delete',
        'superapp:application-management:button:delete',
    ]
}
export const AC_LOG = {
    read: [
        'superapp:activity-log:module:read',
        'superapp:activity-log:view:read',
        'superapp:activity-log:button:read',
    ],
    create: [
        'superapp:activity-log:module:create',
        'superapp:activity-log:view:create',
        'superapp:activity-log:button:create',
    ],
    edit: [
        'superapp:activity-log:module:edit',
        'superapp:activity-log:view:edit',
        'superapp:activity-log:button:edit',
    ],
    delete: [
        'superapp:activity-log:module:delete',
        'superapp:activity-log:view:delete',
        'superapp:activity-log:button:delete',
    ]
}


export const INTERNAL_APPS = [
  { id: '', text: 'Dashboard', path: '/', icon: <HomeOutlined /> },
  { id: 'superapp:user-management:module:read', text: 'User Management', path: '/user-management', icon: <GroupOutlined /> },
  { id: 'superapp:role-management:module:read', text: 'Role Management', path: '/role-management', icon: <SettingsOutlined /> },
  { id: 'superapp:application-management:module:read', text: 'Application Management', path: '/applications', icon: <AppsOutlined /> },
  { id: 'superapp:activity-log:module:read', text: 'Activity Log', path: '/activity-log', icon: <DvrOutlined /> },
];