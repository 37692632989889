/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
    Box, Typography, Button, Table, TableBody, TableCell, TableContainer,
    Autocomplete, Divider,
    TableHead, TableRow, Paper, IconButton, CircularProgress, Alert, Stack, Chip, TextField, FormControl, InputLabel, Select, MenuItem,
    Checkbox
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import FormDrawer from '../components/FormDrawer';
import { useRoleDetail, usePermissions, useReferenceApps } from '../services/queries';
import { useCreateRole, useUpdateRole, useSyncPermissions, useDeleteRole } from '../services/mutations';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DashboardRoleTable from '../components/DashboardRoleTable';
import toast from 'react-hot-toast';

const initialFormState = {
    name: "",
    code: '',
    permissions: [],
    application_codes: []
};

const AddRole = () => {
    const { code } = useParams()
    const { isLoading: isLoadingApp, data: appData, refetch: refetchApp } = useReferenceApps();
    const { isLoading: isLoadingRoleDetail, error: errorRoleDetail, data: roleDetail, refetch: refetchRoleDetail } = useRoleDetail(code);
    const [selectedApp, setSeletedApp] = useState([])
    const { data: permissionsData } = usePermissions();
    const createRole = useCreateRole();
    const updateRole = useUpdateRole();
    const syncPermissions = useSyncPermissions();
    const deleteRole = useDeleteRole();
    const nav = useNavigate()

    const [isOpen, setIsOpen] = useState(false);
    const [form, setForm] = useState(initialFormState);
    const [editMode, setEditMode] = useState(false);
    const [selectedCode, setSelectedCode] = useState(null);
    const [formError, setFormError] = useState(null);

    useEffect(() => {
        refetchApp();
        if (code) refetchRoleDetail()
    }, [code]);

    useEffect(() => {
        if (roleDetail) {
            const { data } = roleDetail
            setForm({ ...data, application_codes: data.applications })
        }
    }, [roleDetail])

    useEffect(() => {
        setForm({ ...form, application_codes: selectedApp })
    }, [selectedApp])

    const handleSubmit = (e) => {
        e.preventDefault()
        const mutation = code ? updateRole : createRole
        mutation.mutate(
            { code, body: { ...form, application_codes: form.application_codes.map(el => el.code) } },
            {
                onSuccess: async (res) => {
                    toast.success('Success add role')
                    nav('/role-management');
                },
                onError: (err) => {
                    const msg = err?.response?.data?.detail || 'Login failed';
                    toast.error(`Error, something wrong | ${msg}`);
                },
            })
    }

    if (code && isLoadingRoleDetail) return (
        <Box display="flex" justifyContent="center" mt={10}><CircularProgress /></Box>
    )

    if (code && errorRoleDetail) return (
        <Box textAlign={'center'}>
            <Typography>Error, {errorRoleDetail?.response?.data?.detail}</Typography>
            <Button component={Link} to={-1}>Back</Button>
        </Box>
    )

    return (
        <Box>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                <Typography variant="h5" gutterBottom>{code ? 'Edit' : 'Add'} Role</Typography>
            </Stack>
            <Stack component={'form'}>
                <Stack width={600}>
                    <TextField
                        name="roleName"
                        label="Role Name"
                        value={form.name}
                        onChange={e => setForm({ ...form, name: e.target.value })}
                        required
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        name="code"
                        label="Code"
                        value={form.code}
                        onChange={e => setForm({ ...form, code: e.target.value })}
                        required
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                    <Autocomplete
                        multiple
                        id="applications"
                        sx={{ mb: 2 }}
                        value={form.application_codes}
                        isOptionEqualToValue={(option, value) => option.code === value.code}
                        options={appData?.data !== undefined ? appData?.data : []}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, newValue) => {
                            setSeletedApp(newValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Applications"
                                placeholder="Applications"
                            />
                        )}
                    />
                </Stack>
                <Stack width={900}>
                    <Divider />
                    <Typography mt={2} mb={1} variant={'h6'} fontWeight={'bold'}>Superapp Dashboard</Typography>
                    <DashboardRoleTable form={form} setForm={setForm} />
                    {
                        selectedApp?.length > 0 && selectedApp.map(el => {
                            return (
                                <Box mt={2} key={el.code}>
                                    <Stack mt={2} mb={1} direction={'row'} alignItems={'center'}>
                                        <img src="/tvri.jpg" alt="TVRI Logo" style={{ width: 40, objectFit: 'contain' }} />
                                        <Typography ml={1} variant={'h6'} fontWeight={'bold'}>{el.name}</Typography>
                                    </Stack>
                                    <Typography mb={2}>{el.description}</Typography>
                                    <Divider />
                                </Box>
                            )
                        })
                    }
                    <Box display={'flex'} justifyContent={'end'} mt={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            type='submit'
                            onClick={handleSubmit}
                            sx={{ width: 100 }}
                        >
                            Save</Button>
                    </Box>
                </Stack>
            </Stack>
        </Box>
    );
};

export default AddRole;
