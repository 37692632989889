import React from 'react';
import PropTypes from 'prop-types';
import { AppBar as MUIAppBar, Toolbar, Typography, Avatar, IconButton, Box, Stack } from '@mui/material';
import { Logout as LogoutIcon } from '@mui/icons-material';
import useAuth from '../hooks/useAuth';

const AppBar = ({ username, setAuth, role }) => {
  const { position, userImage } = useAuth();

  const handleLogout = () => {
    setAuth(false);
  };

  return (
    <MUIAppBar position="fixed">
      <Toolbar>
        <Typography variant="h6" noWrap sx={styles.title}>
          TVRI SuperApps
        </Typography>
        <Box sx={styles.userInfo}>
          <Stack>
            <Typography noWrap sx={styles.username}>{username}</Typography>
            <Typography fontWeight="bold" fontSize={12} textAlign="right" noWrap sx={styles.position}>{position}</Typography>
          </Stack>
          {userImage ? (
            <Avatar alt={username} src={userImage} sx={styles.avatar} />
          ) : (
            <Avatar alt={username} sx={styles.avatar}>{username.charAt(0)}</Avatar>
          )}
          <IconButton color="inherit" onClick={handleLogout} sx={styles.logoutButton}>
            <LogoutIcon />
          </IconButton>
        </Box>
      </Toolbar>
    </MUIAppBar>
  );
};

const styles = {
  title: {
    flexGrow: 1,
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  username: {
    marginLeft: 2,
  },
  position: {
    marginLeft: 2,
  },
  avatar: {
    marginLeft: 2,
  },
  logoutButton: {
    marginLeft: 2,
  },
};

AppBar.propTypes = {
  username: PropTypes.string.isRequired,
  setAuth: PropTypes.func.isRequired,
  role: PropTypes.array,
};

export default AppBar;
