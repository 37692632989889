/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Box, Typography, Button, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, IconButton, CircularProgress, Alert, Stack, Chip, TextField, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import FormDrawer from '../components/FormDrawer';
import { useRoles, usePermissions } from '../services/queries';
import { useCreateRole, useUpdateRole, useSyncPermissions, useDeleteRole } from '../services/mutations';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const initialFormState = {
  roleName: "",
  permissions: []
};

const RoleManagement = () => {
  const { isLoading, data, error, refetch } = useRoles();
  const { data: permissionsData } = usePermissions();
  const createRole = useCreateRole();
  const updateRole = useUpdateRole();
  const syncPermissions = useSyncPermissions();
  const deleteRole = useDeleteRole();
  const nav = useNavigate()
  const { checkPermissionElement } = useAuth();

  const [isOpen, setIsOpen] = useState(false);
  const [form, setForm] = useState(initialFormState);
  const [editMode, setEditMode] = useState(false);
  const [selectedCode, setSelectedCode] = useState(null);
  const [formError, setFormError] = useState(null);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormError(null);
    try {
      if (editMode) {
        await updateRole.mutateAsync({ code: selectedCode, body: form });
      } else {
        await createRole.mutateAsync(form);
      }
      setIsOpen(false);
      setForm(initialFormState);
      refetch();
    } catch (error) {
      setFormError(error.message);
    }
  };

  const handleDelete = useCallback(async (code) => {
    try {
      await deleteRole.mutateAsync(code);
      refetch();
    } catch (error) {
      console.error("Error during role delete:", error);
    }
  }, [deleteRole, refetch]);

  const handleEdit = useCallback((role) => {
    setForm(role);
    setSelectedCode(role.roleName);
    setEditMode(true);
    setIsOpen(true);
  }, []);

  const handleSyncPermissions = useCallback(async (code, permissions) => {
    try {
      await syncPermissions.mutateAsync({ code, permissions });
      refetch();
    } catch (error) {
      console.error("Error during permissions sync:", error);
    }
  }, [syncPermissions, refetch]);

  const renderMore = (data) => {
    if (data.length === 0) return '-'
    if (data.length > 5) {
      const slicedData = data.slice(0, 5);
      const moreCount = data.length - 5;
      return (
        <>
          {slicedData.map(permission => <Chip key={permission} label={permission} sx={{ mr: 1, mb: 1, fontSize: 12 }} />)}
          <Chip label={`...and ${moreCount} more`} sx={{ mr: 1, mb: 1, fontSize: 12 }} />
        </>
      );
    } else {
      return (
        <>
          {data.map(permission => <Chip key={permission} label={permission} sx={{ mr: 1, mb: 1, fontSize: 12 }} />)}
        </>
      );
    }
  };

  const renderTableRows = useMemo(() => (
    data?.data?.length > 0 && data.data.map((role, i) => (
      <TableRow key={role.name}>
        <TableCell sx={{ borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>{i + 1}</TableCell>
        <TableCell sx={{ borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>{role.name}</TableCell>
        <TableCell sx={{ borderBottom: '2px solid rgba(224, 224, 224, 1)' }}><Chip label={role.code} /></TableCell>
        <TableCell sx={{ borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>
          {role.applications.map((app) => (
            <Chip key={app.code} label={app.name} sx={{ mr: 1, mb: 1, fontSize: 12 }} />
          ))}
        </TableCell>
        <TableCell sx={{ borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>
          {renderMore(role?.permissions)}
        </TableCell>
        <TableCell sx={{ borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>
          {
            checkPermissionElement('superapp:role-management:button:edit') &&
            <IconButton onClick={() => nav(`edit/${role.code}`)}>
              <EditIcon fontSize='small' />
            </IconButton>
          }
          {
            checkPermissionElement('superapp:role-management:button:delete') &&
            <IconButton onClick={() => handleDelete(role.roleName)}>
              <DeleteIcon fontSize='small' />
            </IconButton>
          }
        </TableCell>
      </TableRow>
    ))
  ), [data, handleEdit, handleDelete]);

  return (
    <Box>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" gutterBottom>Role Management</Typography>
        {
          checkPermissionElement('superapp:role-management:button:create') &&
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<AddIcon />}
            onClick={() => nav('add')}
          >
            Add Role
          </Button>
        }
      </Stack>
      {error && <Alert severity="error" sx={{ mt: 2 }}>{error.message}</Alert>}
      {isLoading ? (
        <Box display="flex" justifyContent="center" mt={10}><CircularProgress /></Box>
      ) : (
        <TableContainer component={Paper} sx={{ mt: 3 }}>
          <Table sx={{ border: '2px solid rgba(224, 224, 224, 1)' }}>
            <TableHead>
              <TableRow>
                <TableCell width={50} sx={{ fontWeight: 'bold', borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>No</TableCell>
                <TableCell sx={{ fontWeight: 'bold', borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>Role</TableCell>
                <TableCell sx={{ fontWeight: 'bold', borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>Code</TableCell>
                <TableCell sx={{ fontWeight: 'bold', borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>Applications</TableCell>
                <TableCell sx={{ fontWeight: 'bold', borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>Permissions</TableCell>
                <TableCell width={120} sx={{ fontWeight: 'bold', borderBottom: '2px solid rgba(224, 224, 224, 1)' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderTableRows}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <FormDrawer isOpen={isOpen} setIsOpen={setIsOpen}>
        <Stack onSubmit={handleSubmit} component="form" p={3} width={400} maxWidth={400}>
          <Typography mb={2} variant="h6">{editMode ? 'Edit Role' : 'Add Role'}</Typography>
          {formError && <Alert severity="error" sx={{ mb: 2 }}>{formError}</Alert>}
          <TextField
            name="roleName"
            label="Role Name"
            value={form.roleName}
            onChange={handleChangeInput}
            fullWidth
            sx={{ mb: 2 }}
          />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Permissions</InputLabel>
            <Select
              multiple
              value={form.permissions}
              onChange={(e) => setForm({ ...form, permissions: e.target.value })}
              renderValue={(selected) => selected.join(', ')}
            >
              {permissionsData?.data.map((permission) => (
                <MenuItem key={permission} value={permission}>
                  {permission}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <LoadingButton type="submit" variant="contained" sx={{ mt: 4, p: 1 }}>
            {editMode ? 'Update' : 'Add'}
          </LoadingButton>
        </Stack>
      </FormDrawer>
    </Box>
  );
};

export default RoleManagement;
