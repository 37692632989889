import {
    Typography, Table, TableBody, TableCell, TableHead, TableRow, Stack, Checkbox
} from '@mui/material';
import { USER_MGMT, ROLE_MGMT, APP_MGMT, AC_LOG } from '../helper/constants';

const DashboardRoleTable = ({ form, setForm }) => {
    const handleChangeCheckbox = (checked, permission) => {
        let arr = [...form.permissions]
        if (checked) {
            arr.push(permission);
        } else {
            arr = form.permissions.filter(p => p !== permission);
        }
        setForm({ ...form, permissions: arr })
    }

    const mainCheckboxChecked = (permissions) => {
        return permissions.every(permission => form.permissions.includes(permission));
    };

    const mainCheckboxIndeterminate = (permissions) => {
        const checkedCount = permissions.filter(permission => form.permissions.includes(permission)).length;
        return checkedCount > 0 && checkedCount < permissions.length;
    };

    const handleMainCheckboxChange = (e, checked, permissions) => {
        if (checked) {
            setForm({ ...form, permissions: [...form.permissions, ...permissions] });
        } else {
            setForm({ ...form, permissions: form.permissions.filter(p => !permissions.includes(p)) });
        }
    };

    const PermissionsTableCell = ({ permissions }) => {
        return (
            <TableCell sx={{ textAlign: 'center' }}>
                <Stack direction={'column'}>
                    <Checkbox
                        checked={mainCheckboxChecked(permissions)}
                        indeterminate={mainCheckboxIndeterminate(permissions)}
                        onChange={(e, checked) => handleMainCheckboxChange(e, checked, permissions)}
                        sx={{ p: 0.5 }}
                    />
                    {permissions.map(permission => (
                        <Checkbox
                            key={permission}
                            checked={form.permissions.includes(permission)}
                            onChange={(e, checked) => handleChangeCheckbox(checked, permission)}
                            sx={{ p: 0.5 }}
                        />
                    ))}
                </Stack>
            </TableCell>
        );
    };

    return (
        <Table>
            <TableHead sx={{ backgroundColor: '#eee' }}>
                <TableRow>
                    <TableCell width={'50%'}>NAME</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>READ</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>CREATE</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>EDIT</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>DELETE</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell width={'50%'}>
                        <Typography mb={1}>User Management</Typography>
                        <ul style={{ paddingLeft: 14 }}>
                            <li><Typography mb={1}>Module</Typography></li>
                            <li><Typography mb={1}>View</Typography></li>
                            <li><Typography mb={1}>Button</Typography></li>
                        </ul>
                    </TableCell>
                    <PermissionsTableCell permissions={USER_MGMT.read} />
                    <PermissionsTableCell permissions={USER_MGMT.create} />
                    <PermissionsTableCell permissions={USER_MGMT.edit} />
                    <PermissionsTableCell permissions={USER_MGMT.delete} />
                </TableRow>
                <TableRow>
                    <TableCell width={'50%'}>
                        <Typography mb={1}>Role Management</Typography>
                        <ul style={{ paddingLeft: 14 }}>
                            <li><Typography mb={1}>Module</Typography></li>
                            <li><Typography mb={1}>View</Typography></li>
                            <li><Typography mb={1}>Button</Typography></li>
                        </ul>
                    </TableCell>
                    <PermissionsTableCell permissions={ROLE_MGMT.read} />
                    <PermissionsTableCell permissions={ROLE_MGMT.create} />
                    <PermissionsTableCell permissions={ROLE_MGMT.edit} />
                    <PermissionsTableCell permissions={ROLE_MGMT.delete} />
                </TableRow>
                <TableRow>
                    <TableCell width={'50%'}>
                        <Typography mb={1}>Application Management</Typography>
                        <ul style={{ paddingLeft: 14 }}>
                            <li><Typography mb={1}>Module</Typography></li>
                            <li><Typography mb={1}>View</Typography></li>
                            <li><Typography mb={1}>Button</Typography></li>
                        </ul>
                    </TableCell>
                    <PermissionsTableCell permissions={APP_MGMT.read} />
                    <PermissionsTableCell permissions={APP_MGMT.create} />
                    <PermissionsTableCell permissions={APP_MGMT.edit} />
                    <PermissionsTableCell permissions={APP_MGMT.delete} />
                </TableRow>
                <TableRow>
                    <TableCell width={'50%'}>
                        <Typography mb={1}>Activity Log</Typography>
                        <ul style={{ paddingLeft: 14 }}>
                            <li><Typography mb={1}>Module</Typography></li>
                            <li><Typography mb={1}>View</Typography></li>
                            <li><Typography mb={1}>Button</Typography></li>
                        </ul>
                    </TableCell>
                    <PermissionsTableCell permissions={AC_LOG.read} />
                    <PermissionsTableCell permissions={AC_LOG.create} />
                    <PermissionsTableCell permissions={AC_LOG.edit} />
                    <PermissionsTableCell permissions={AC_LOG.delete} />
                </TableRow>
            </TableBody>
        </Table>
    )
}

export default DashboardRoleTable;