/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Box, Typography, Button, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, IconButton, Chip, TextField, Checkbox, FormControlLabel,
  CircularProgress, Alert, Stack, Select, MenuItem, InputLabel, FormControl
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, InsertLinkOutlined } from '@mui/icons-material';
import { useApplications } from '../services/queries';
import { useCreateApplication, useUpdateApplication, useSetStatusApplication, useDeleteApplication } from '../services/mutations';
import FormDrawer from '../components/FormDrawer';
import LoadingButton from '@mui/lab/LoadingButton';
import ConfirmationModal from '../components/ConfirmationModal';
import toast from 'react-hot-toast';
import useAuth from '../hooks/useAuth'

const initialFormState = {
  code: "",
  name: "",
  host: "",
  auth_uri: "/",
  health_uri: "/health",
  description: "",
  is_active: true,
  has_credentials: false,
  credentials: {
    username: '',
    password: ''
  }
};

const Applications = () => {
  const { isLoading, data, error, refetch } = useApplications();
  const createApplication = useCreateApplication();
  const updateApplication = useUpdateApplication();
  const setStatusApplication = useSetStatusApplication();
  const deleteApplication = useDeleteApplication();
  const { checkPermissionElement } = useAuth();

  const [isOpen, setIsOpen] = useState(false);
  const [form, setForm] = useState(initialFormState);
  const [editMode, setEditMode] = useState(false);
  const [selectedCode, setSelectedCode] = useState(null);
  const [formError, setFormError] = useState(null);
  const [localData, setLocalData] = useState([]);
  const [loading, setLoading] = useState({ submit: false, delete: false, toggle: false });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [codeToDelete, setCodeToDelete] = useState(null);

  useEffect(() => {
    refetch()
  }, [refetch]);

  useEffect(() => {
    if (data) {
      setLocalData(data.data);
    }
  }, [data]);
  useEffect(() => {
    if (data) {
      setLocalData(data.data);
    }
  }, [data]);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleStatusChange = (e) => {
    const { value } = e.target;
    setForm((prevForm) => ({ ...prevForm, is_active: value === 'true' }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormError(null);
    setLoading((prevLoading) => ({ ...prevLoading, submit: true }));
    const newForm = { ...form }
    if (!form.credentials.username || !form.credentials.password) delete newForm['credentials']
    try {
      if (editMode) {
        await updateApplication.mutateAsync({ code: selectedCode, body: newForm });
      } else {
        await createApplication.mutateAsync(newForm);
      }
      setIsOpen(false);
      setForm(initialFormState);
      refetch();
      toast.success('Success add new application')
    } catch (error) {
      setFormError(error.message);
    } finally {
      setLoading((prevLoading) => ({ ...prevLoading, submit: false }));
    }
  };

  const handleDelete = useCallback((code) => {
    setCodeToDelete(code);
    setIsModalOpen(true);
  }, []);

  const confirmDelete = async () => {
    setLoading((prevLoading) => ({ ...prevLoading, delete: true }));
    try {
      await deleteApplication.mutateAsync(codeToDelete);
      setIsModalOpen(false);
      refetch();
    } catch (error) {
      console.error("Error during application delete:", error);
    } finally {
      setLoading((prevLoading) => ({ ...prevLoading, delete: false }));
    }
  };

  const handleToggleStatus = useCallback(async (code, is_active) => {
    setLoading((prevLoading) => ({ ...prevLoading, toggle: true }));
    const updatedData = localData.map(app => {
      if (app.code === code) {
        return { ...app, is_active: !is_active };
      }
      return app;
    });
    setLocalData(updatedData);

    try {
      await setStatusApplication.mutateAsync({ code, is_active: !is_active });
      refetch();
    } catch (error) {
      console.error("Error during status toggle:", error);
    } finally {
      setLoading((prevLoading) => ({ ...prevLoading, toggle: false }));
    }
  }, [localData, setStatusApplication, refetch]);

  const handleEdit = useCallback((application) => {
    setForm({
      ...application,
      is_active: application.is_active ? "true" : "false"
    });
    setSelectedCode(application.code);
    setEditMode(true);
    setIsOpen(true);
  }, []);

  const renderTableRows = useMemo(() => (
    localData.map((el, i) => (
      <TableRow key={el.code}>
        <TableCell sx={styles.tableCell}>{i + 1}</TableCell>
        <TableCell sx={styles.tableCell}>
          <Box margin="auto" display="flex" alignItems="center">
            <Box>
              <Typography>{el.name}</Typography>
              <Typography sx={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
                <InsertLinkOutlined sx={styles.icon} />
                {el.host}
              </Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell>{<Chip sx={styles.chip} label={el.code} />}</TableCell>
        <TableCell>
          <Chip color={el.is_active ? 'success' : 'default'} sx={styles.chip} label={el.is_active ? 'Aktif' : 'Nonaktif'} />
        </TableCell>
        <TableCell sx={styles.tableCell}>
          {
            checkPermissionElement('superapp:application-management:button:edit') &&
            <IconButton onClick={() => handleEdit(el)}>
              <EditIcon fontSize="small" />
            </IconButton>
          }
          {
            checkPermissionElement('superapp:application-management:button:delete') &&
            <IconButton onClick={() => handleDelete(el.code)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          }
        </TableCell>
      </TableRow>
    ))
  ), [localData, handleEdit, handleDelete]);

  return (
    <Box>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" gutterBottom>Application Management</Typography>
        {
          checkPermissionElement('superapp:application-management:button:create') &&
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<AddIcon />}
            onClick={() => {
              setForm(initialFormState);
              setEditMode(false);
              setIsOpen(true);
            }}
          >
            Add Application
          </Button>
        }
      </Stack>
      {error && <Alert severity="error" sx={{ mt: 2 }}>{error.message}</Alert>}
      {isLoading ? (
        <Box display="flex" justifyContent="center" mt={10}><CircularProgress /></Box>
      ) : (
        <TableContainer component={Paper} sx={{ mt: 3 }}>
          <Table sx={styles.table}>
            <TableHead>
              <TableRow>
                {['No', 'Application Name', 'Code', 'Status', 'Action'].map(header => (
                  <TableCell key={header} sx={styles.tableHeader}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {renderTableRows}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <FormDrawer isOpen={isOpen} setIsOpen={setIsOpen}>
        <Stack onSubmit={handleSubmit} component="form" fullWidth>
          <Typography mb={2} variant="h6">{editMode ? 'Edit Application' : 'Add Application'}</Typography>
          {formError && <Alert severity="error" sx={{ mb: 2 }}>{formError}</Alert>}
          <TextField
            sx={{ mb: 2 }}
            fullWidth
            type="text"
            id="name"
            required
            label="Application Name"
            name="name"
            value={form.name}
            onChange={handleChangeInput}
          />
          <TextField
            sx={{ mb: 2 }}
            fullWidth
            type="text"
            required
            id="code"
            label="Code"
            name="code"
            value={form.code}
            onChange={handleChangeInput}
            disabled={editMode}
          />
          <TextField
            sx={{ mb: 2 }}
            fullWidth
            type="text"
            required
            id="host"
            label="Application URL"
            name="host"
            value={form.host}
            onChange={handleChangeInput}
          />
          <TextField
            sx={{ mb: 2 }}
            fullWidth
            type="text"
            required
            id="auth_uri"
            label="Login Path"
            name="auth_uri"
            value={form.auth_uri}
            onChange={handleChangeInput}
          />
          <TextField
            sx={{ mb: 2 }}
            fullWidth
            type="text"
            id="description"
            label="Description"
            name="description"
            value={form.description}
            multiline
            rows={4}
            onChange={handleChangeInput}
          />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              id="is_active"
              name="is_active"
              value={form.is_active}
              onChange={handleStatusChange}
              label="Status"
            >
              <MenuItem value={true}>Aktif</MenuItem>
              <MenuItem value={false}>Nonaktif</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            label="Has Credentials?"
            control={<Checkbox checked={form.has_credentials} onChange={(e, checked) => setForm({ ...form, has_credentials: checked })} />}
          />
          {
            form?.has_credentials &&
            <Box>
              <TextField
                sx={{ mb: 2 }}
                fullWidth
                type="text"
                id="username"
                label="Username/Email/NIK"
                name="username"
                value={form?.credentials?.username}
                onChange={e => setForm({ ...form, credentials: { ...form.credentials, username: e.target.value } })}
              />
              <TextField
                sx={{ mb: 2 }}
                fullWidth
                type="password"
                id="password"
                label="Password"
                name="password"
                value={form?.credentials?.password}
                onChange={e => setForm({ ...form, credentials: { ...form.credentials, password: e.target.value } })}
              />
            </Box>
          }
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ mt: 4, p: 1 }}
            loading={loading.submit}
          >
            {editMode ? 'Update' : 'Add'}
          </LoadingButton>
        </Stack>
      </FormDrawer>

      <ConfirmationModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={confirmDelete}
        title="Confirm Delete"
        description="Are you sure you want to delete this application?"
      />
    </Box>
  );
};

const styles = {
  table: {
    border: '2px solid rgba(224, 224, 224, 1)',
  },
  tableHeader: {
    fontWeight: 'bold',
    borderBottom: '2px solid rgba(224, 224, 224, 1)',
  },
  tableCell: {
    borderBottom: '2px solid rgba(224, 224, 224, 1)',
  },
  chip: {
    marginRight: 5,
    fontSize: 12,
  },
  icon: {
    fontSize: 20,
    mr: '3px',
    color: '#1876d2',
  },
};

export default Applications;
