export const mockCards = [
    {
    title: 'Card 1',
    imageUrl: 'https://via.placeholder.com/150',
    subItems: ['SubItem 1', 'SubItem 2'] },
    {
    title: 'Card 2',
    imageUrl: 'https://via.placeholder.com/150',
    subItems: ['SubItem 3', 'SubItem 4'] },
  ];
  
  export const mockUsers = [
    {
    id: 1,
    name: 'User 1',
    email: 'user1@example.com',
    username: 'user1',
    password: 'password1',
    role: 'user',
    access: ['Access 1', 'Access 2'] },
    {
    id: 2,
    name: 'User 2',
    email: 'user2@example.com',
    username: 'user2',
    password: 'password2',
    role: 'admin',
    access: ['Access 3', 'Access 4']
    },
    {
    id: 3,
    name: 'User 3',
    email: 'user3@example.com',
    username: 'user3',
    password: 'password3',
    role: 'superadmin' 
    },
  ];
  
  export const mockRoles = ['user', 'admin', 'superadmin'];