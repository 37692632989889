import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Drawer, useMediaQuery, useTheme } from '@mui/material';

const FormDrawer = ({ isOpen, setIsOpen, children }) => {
  const handleDrawerClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Drawer
      open={isOpen}
      onClose={handleDrawerClose}
      anchor="right"
      sx={styles.drawer(isSmallScreen)}
      PaperProps={{
        sx: {
          width: isSmallScreen ? '100%' : 400,
          maxHeight: '100vh',
          overflowY: 'auto',
        },
      }}
    >
      {children}
    </Drawer>
  );
};

const styles = {
  drawer: (isSmallScreen) => ({
    '& .MuiDrawer-paper': {
      width: isSmallScreen ? '100%' : 400,
      padding: 2,
      maxHeight: '100vh',
      overflowY: 'auto',
    },
  }),
};

FormDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default FormDrawer;
