import React, { useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { DataProvider } from './context/DataContext';
import Layout from './components/Layout';
import Dashboard from './pages/Dashboard';
import Settings from './pages/Settings';
import Login from './pages/Login';
import UserManagement from './pages/UserManagement';
import RoleManagement from './pages/RoleManagement';
import ProtectedRoute from './components/ProtectedRoute';
import useAuth from './hooks/useAuth';
import Applications from './pages/Applications';
import ActivityLog from './pages/ActivityLog';
import AddRole from './pages/AddRole';
import { Typography } from '@mui/material';

function App() {
  const { username, role, setAuth, setUsername, setRole } = useAuth();

  const memoizedRole = useMemo(() => role, [role]);
  const memoizedUsername = useMemo(() => username, [username]);

  return (
    <DataProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login setAuth={setAuth} setUsername={setUsername} setRole={setRole} />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<Layout setAuth={setAuth} username={memoizedUsername} role={memoizedRole} />}>
              <Route index element={<Dashboard />} />
              <Route path="applications" element={<Applications />} />
              <Route path="settings" element={<Settings />} />
              <Route path="user-management" element={<UserManagement />} />
              <Route path="role-management" element={<RoleManagement />} />
              <Route path="role-management/add" element={<AddRole />} />
              <Route path="role-management/edit/:code" element={<AddRole />} />
              <Route path="activity-log" element={<ActivityLog />} />
            </Route>
          </Route>
          <Route path="*" element={<Typography component={'h5'} sx={{ textAlign: 'center', mt: 5 }}>Page not found</Typography>} />
        </Routes>
      </Router>
    </DataProvider>
  );
}

export default App;
