import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, TextField, DialogTitle, Button } from '@mui/material';

const ModalSetUserPass = ({ open, onClose, onConfirm, setCredsPayload, credsPayload }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Login</DialogTitle>
            <DialogContent>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Username/NIK/Email"
                    name="username"
                    autoComplete="username"
                    autoFocus
                    value={credsPayload.username}
                    onChange={(e) => setCredsPayload({ ...credsPayload, username: e.target.value })}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={credsPayload.password}
                    onChange={(e) => setCredsPayload({ ...credsPayload, password: e.target.value })}
                />
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={onConfirm} color="primary" autoFocus>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

// ModalSetUserPass.propTypes = {
//     open: PropTypes.bool.isRequired,
//     onClose: PropTypes.func.isRequired,
//     onConfirm: PropTypes.func.isRequired,
//     title: PropTypes.string.isRequired,
//     description: PropTypes.string.isRequired,
// };

export default ModalSetUserPass;
