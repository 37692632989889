import React from 'react';
import { Typography, Box } from '@mui/material';

const Settings = () => (
  <Box sx={{ p: 3 }}>
    <Typography variant="h4" gutterBottom>Settings Page</Typography>
    <Typography>This is the Settings Page.</Typography>
  </Box>
);

export default Settings;
