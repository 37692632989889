import React, { useEffect, useMemo, useState } from 'react';
import { Typography, Box, CircularProgress, Grid, Alert } from '@mui/material';
import { useLocation } from 'react-router-dom';
import DashboardCard from '../components/DashboardCard';
import { useAuthApp } from '../services/queries';
import { INTERNAL_APPS } from '../helper/constants';
import ModalSetUserPass from '../components/ModalSetUserPass';
import useAuth from '../hooks/useAuth';
import { useLoginApps } from '../services/mutations';
import toast from "react-hot-toast";

const initForm = { application_code: '', username: '', password: '' }
const Dashboard = () => {
  const { isLoading, data, error, refetch } = useAuthApp();
  const [selectedCard, setSelectedCard] = useState(null)
  const location = useLocation();
  const showCards = useMemo(() => location.pathname === '/', [location.pathname]);
  const { filteredApps, isLoading: isLoadingAuthMe, originUsername } = useAuth();
  const loginApps = useLoginApps();
  const [credsPayload, setCredsPayload] = useState(initForm)

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleSubmitLoginApp = async (e) => {
    e.preventDefault();
    const body = { ...credsPayload, application_code: selectedCard?.code }

    if (!credsPayload.username || !credsPayload.password) {
      toast.error('Username or password are required');
      return;
    }

    loginApps.mutate(
      { originUsername, body },
      {
        onSuccess: async (res) => {
          // const token = res?.data?.access_token;
          // if (token) {
          //   setUsername(usernameInput);
          //   navigate('/');
          // }
          console.log(res);
        },
        onError: (err) => {
          const msg = err?.response?.data?.detail || 'Set username or password is failed';
          // setError(msg);
          toast.error(`Error, ${msg}`);
        },
      }
    );
  };

  const renderCards = useMemo(() => (
    data?.data && data.data.length > 0 ? (
      <Grid container spacing={3} justifyContent="flex-start">
        {data.data.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4} key={index}>
            <DashboardCard setSelectedCard={setSelectedCard} card={card} />
          </Grid>
        ))}
      </Grid>
    ) : (
      <Typography variant="body1" sx={{ mt: 2 }}>
        Tidak ada daftar aplikasi tersedia
      </Typography>
    )
  ), [data]);

  const internalApps = () => {
    return (
      filteredApps.map((el, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4} key={index}>
          <DashboardCard isInternal card={el} />
        </Grid>
      ))
      // <Typography variant="body1" sx={{ ml: 3, mt: 3 }}>
      //   Tidak ada daftar aplikasi tersedia
      // </Typography>
    )
  }

  const internalAppsMemo = useMemo(() => {
    return (
      INTERNAL_APPS.map((el, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4} key={index}>
          <DashboardCard isInternal card={el} />
        </Grid>
      ))
      // <Typography variant="body1" sx={{ mt: 2 }}>
      //   Tidak ada daftar aplikasi tersedia
      // </Typography>
    )
  }, []);

  if (isLoadingAuthMe || isLoading) return <Box display="flex" justifyContent="center" mt={10}><CircularProgress /></Box>;

  return (
    <Box>
      <Box margin="auto" mb={2} display="flex" alignItems="center" justifyContent="center">
        <img src="/tvri.jpg" alt="TVRI Logo" style={{ width: 150 }} />
      </Box>
      <ModalSetUserPass
        open={selectedCard ? true : false}
        onClose={() => setSelectedCard(null)}
        onConfirm={handleSubmitLoginApp}
        setCredsPayload={setCredsPayload}
        credsPayload={credsPayload}
      />
      <Typography textAlign="center" mb={5} fontWeight="bold" variant="h5">
        Welcome to TVRI Superapps
      </Typography>
      {error && <Alert severity="error" sx={{ mt: 2 }}>{error.message}</Alert>}
      <Box mb={4}>
        <Typography variant='h6' mb={1} fontWeight={'bold'}>Internal Apps</Typography>
        <Grid container spacing={3} justifyContent="flex-start">
          {internalApps()}
        </Grid>
      </Box>
      <Box>
        <Typography variant='h6' mb={1} fontWeight={'bold'}>External Apps</Typography>
        {showCards && renderCards}
      </Box>
    </Box >
  );
};

export default Dashboard;
