/* eslint-disable no-unused-vars */
import { useState, useEffect, useCallback } from 'react';
import { useAuthMe } from '../services/queries';
import { INTERNAL_APPS } from '../helper/constants';

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [originUsername, setOriginUsername] = useState('');
  const [username, setUsername] = useState('');
  const [userImage, setUserImage] = useState('');
  const [position, setPosition] = useState('');
  const [permissions, setPermissions] = useState([]);
  const [role, setRole] = useState([]);
  const [filteredApps, setFilteredApps] = useState(INTERNAL_APPS)

  const { isLoading, data, error, refetch, status } = useAuthMe();

  const checkPermissionElement = (par) => {
    if (data?.data?.permissions) {
      return permissions.includes(par)
    }
  }

  const checkAuthStatus = useCallback(() => {
    const token = localStorage.getItem('token');
    if (token) {
      refetch();
      setIsAuthenticated(true);
    } else {
      localStorage.removeItem('token');
      setIsAuthenticated(false);
    }
  }, [refetch]);

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  useEffect(() => {
    if (data && data.data) {
      const dat = data.data;
      setOriginUsername(dat?.username || '');
      setUsername(dat?.full_name || '');
      setPosition(dat?.profile?.position || '');
      setUserImage(dat?.picture_url || '');
      setRole(dat?.roles || []);
      setPermissions(dat?.permissions || []);
      const filteredApps = INTERNAL_APPS.filter(app => app.id === '' || dat?.permissions.includes(app.id));
      setFilteredApps(filteredApps)
    }
  }, [data]);

  const setAuth = (authStatus) => {
    setIsAuthenticated(authStatus);
    if (!authStatus) {
      localStorage.removeItem('token');
      setUsername('');
      setRole([]);
      setUserImage('');
      setPosition('');
      setPermissions([]);
    }
  };

  return { isAuthenticated, username, position, permissions, role, status, setAuth, setUsername, setRole, originUsername, userImage, isLoading, filteredApps, checkPermissionElement };
};

export default useAuth;
