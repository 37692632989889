import { useMutation } from 'react-query';
import axios from 'axios';

const url = process.env.REACT_APP_API_URL + '/api/v1';

const instance = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json'
  }
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const useLogin = () => useMutation({
  mutationFn: (body) => axios.post(`${url}/auth/token`, body, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
});

export const useCreateApplication = () => useMutation({
  mutationFn: async (body) => {
    const { data } = await instance.post('/application', body);
    return data;
  }
});

export const useUpdateApplication = () => useMutation({
  mutationFn: async ({ code, body }) => {
    const { data } = await instance.put(`/application/${code}`, body);
    return data;
  }
});

export const useSetStatusApplication = () => useMutation({
  mutationFn: async ({ code, is_active }) => {
    const { data } = await instance.patch(`/application/${code}/status`, { is_active });
    return data;
  }
});

export const useDeleteApplication = () => useMutation({
  mutationFn: async (code) => {
    const { data } = await instance.delete(`/application/${code}`);
    return data;
  }
});

export const useCreateUser = () => useMutation({
  mutationFn: async (body) => {
    const { data } = await instance.post('/user', body);
    return data;
  }
});

export const useUpdateUser = () => useMutation({
  mutationFn: async ({ username, body }) => {
    const { data } = await instance.put(`/user/${username}`, body);
    return data;
  }
});

export const useDeleteUser = () => useMutation({
  mutationFn: async (username) => {
    const { data } = await instance.delete(`/user/${username}`);
    return data;
  }
});

export const useCreateRole = () => useMutation({
  mutationFn: async ({ body }) => {
    const { data } = await instance.post('/role', body);
    return data;
  }
});

export const useUpdateRole = () => useMutation({
  mutationFn: async ({ code, body }) => {
    const { data } = await instance.put(`/role/${code}`, body);
    return data;
  }
});

export const useSyncPermissions = () => useMutation({
  mutationFn: async ({ code, permissions }) => {
    const { data } = await instance.post(`/role/${code}/permissions`, { permissions });
    return data;
  }
});

export const useDeleteRole = () => useMutation({
  mutationFn: async (code) => {
    const { data } = await instance.delete(`/role/${code}`);
    return data;
  }
});

export const useGetSecret = () => useMutation({
  mutationFn: async (body) => {
    const { data } = await instance.post(`/auth/one-time-secret`, body);
    return data;
  }
});

export const useLoginApps = () => useMutation({
  mutationFn: async ({ originUsername, body }) => {
    const { data } = await instance.post(`/user/${originUsername}/credential`, body);
    return data;
  }
});
